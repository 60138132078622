<template>

<router-link v-tooltip="text" :to="{name: route, params: params || defaultParams}" class="item" :class="{'is-active': active, 'is-divide': divide}">

	<app-icon :icon="icon" class="item-icon" />
	
	<div class="item-count" v-if="count" :class="{'is-highlight': countHighlight}">{{ count }}</div>

</router-link>

</template>

<script>

export default {

	props: ['text', 'route', 'icon', 'params', 'divide', 'count', 'count-highlight'],

	computed: {
		
		defaultParams: function() {

			return {
				organisation: this.$store.getters['context/organisation'].slug,
				convention: this.$store.getters['context/convention'].slug
			}

		},

		active: function() {

			return this.route !== undefined && (this.route === this.$route.name || this.$_.contains(this.$route.meta.parents, this.route))

		}

	}

}

</script>

<style scoped>

.item {
	display: flex;
	flex-direction: row;
	width: 40px;
	height: 40px;
	align-items: center;
	justify-content: center;
	color: #4d5b6e;
	cursor: pointer;
	padding: 4px;
}

.item.is-divide {
}

.item:first-child {
	margin-top: 20px;
}

.item:hover {
	color: #fff;
}

.item.is-active .item-icon {
	color: #ffbe7a;
}

.item-icon {
	text-align: center;
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	line-height: 32px;
	text-align: center;
	border-radius: 4px;
	font-size: 20px;
}

.item-count {
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	border-radius: 4px;
	text-align: center;
	background-color: #687589;
	font-weight: 400;
	line-height: 24px;
	color: #b4b8bc;
}

.item-count.is-highlight {
	background-color: #dd4848;
	color: #fff;
}

.item.is-active .item-count {
	background-color: #fff;
	color: #333;
}

</style>
